/* eslint-disable @typescript-eslint/naming-convention */
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit
} from "@angular/core";
import * as d3 from "d3";
import { LG_D3_PIE_DEFAULT_DURATION, LgD3PieInterpolator } from "../d3";
import { LgD3PieInterpolatorFactory } from "../d3/lg-d3-pie-interpolator-factory.service";
import { LgSimpleChanges } from "@logex/framework/types";
import { BaseChartComponent } from "../shared/base-chart.component";
import { Margin } from "../shared/chart.types";
import { IPieChartItemMini } from "./pie-chart-mini.types";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LgColorsConfiguration
} from "../shared/lg-color-palette-v2/lg-colors.types";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";
import { IPieChartTooltipContext } from "../lg-compare-pie-chart/pie-chart.types";

const INNER_RADIUS_RATIO = 0.6;
const DEFAULT_MARGIN: Required<Margin> = { top: 8, right: 8, bottom: 8, left: 8 };
const groupNames = ["filled", "empty"];

@Component({
    selector: "lg-compare-pie-chart-mini",
    viewProviders: [useTranslationNamespace("FW._Directives._Charts._LgPieChart")],
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgComparePieChartMiniComponent
    extends BaseChartComponent<IPieChartItemMini[], IPieChartTooltipContext>
    implements OnInit, OnChanges, OnDestroy
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _interpolatorFactory = inject(LgD3PieInterpolatorFactory);

    /**
     * Specifies how much of the circular pie chart is filled.
     */
    @Input({ required: true }) percentage!: number;

    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;

    /**
     * Specifies the margin for chart.
     * Defaults to 8 on all sides.
     *
     * @property { number } top specifies the margin of SVG from top.
     * @property { number } right specifies the margin of SVG from right.
     * @property { number } bottom specifies the margin of SVG from bottom.
     * @property { number } left specifies the margin of SVG from left.
     */
    @Input() margin: Margin = DEFAULT_MARGIN;

    @HostBinding("class") get class(): string {
        return "lg-compare-pie-chart";
    }

    _valueColorsScale!: d3.ScaleOrdinal<string, string>;

    protected override _margin!: Required<Margin>;

    private _pie!: d3.Pie<any, any>;
    private _xScale!: d3.ScaleBand<any>;
    private _interpolator!: LgD3PieInterpolator;

    ngOnInit(): void {
        super._onInit();

        this._initState();
        this._sizePropsToState();
        this._drawMainSvgHolder(this._elementRef.nativeElement);
        this._updateSize();
        this._interpolator = this._interpolatorFactory.interpolator();
        this._convertData();
        this._create();
        this._render(true);
        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgComparePieChartMiniComponent>): void {
        if (!this._initialized) {
            return;
        }

        super._onBaseChartChanges(changes);

        let needsRender = false;
        let renderAsTransition = false;

        if (changes.percentage || changes.colorConfiguration) {
            this._convertData();
            needsRender = true;
            renderAsTransition = true;
        }

        if (changes.width || changes.height) {
            this._sizePropsToState();
            this._updateSize();
            needsRender = true;
        }

        if (needsRender) {
            if (renderAsTransition) {
                d3.transition()
                    .duration(500)
                    .each(() => this._render(true));
            } else {
                this._render(false);
            }
        }
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    protected _initState(): void {
        this._data = [];
    }

    protected _sizePropsToState(): void {
        this._margin = { ...DEFAULT_MARGIN, ...this.margin };
        this._width = this.width - this._margin.left - this._margin.right;
        this._height = this.height - this._margin.top - this._margin.bottom;
    }

    protected _updateSize(): void {
        this._svg.attr("width", +this.width + this._margin.left + this._margin.right);
        this._svg.attr("height", +this.height + this._margin.top + this._margin.bottom);
    }

    protected _render(useTransition: boolean): void {
        if (this._height < 0) {
            return;
        }

        this._xScale.rangeRound([0, this._width]).padding(0).domain(groupNames);

        const { radius, horizontalPadding, verticalPadding } = getPieDimensions({
            width: this.width,
            height: this.height,
            margin: this._margin
        });

        this._interpolator.outerRadius(radius, radius * INNER_RADIUS_RATIO).prepareRender();

        const groups = this._chart
            .selectAll<SVGGElement, IPieChartItemMini[]>(".lg-compare-pie-chart__group")
            .data(this._data, (_, i) => groupNames[i]);

        const groupsG = groups
            .merge(groups)
            .attr(
                "transform",
                (_, i) =>
                    `translate( ${this._margin.left + radius + horizontalPadding}, ${
                        this._margin.top + radius + verticalPadding
                    } )`
            );

        if (useTransition) groupsG.transition();
        groups.exit().transition().style("opacity", 0).remove();

        const pieData: Array<d3.PieArcDatum<any>> = this._pie as unknown as Array<
            d3.PieArcDatum<any>
        >;
        const slices = this._chart
            .selectAll(".lg-compare-pie-chart__group")
            .select("g.lg-compare-pie-chart__group__slices")
            .selectAll<SVGPathElement, d3.PieArcDatum<any>>(
                "path.lg-compare-pie-chart__group__slices__slice"
            )
            .data(pieData, (d: d3.PieArcDatum<any>) => d.data.groupIndex);

        slices
            .enter()
            .append("path")
            .attr("class", "lg-compare-pie-chart__group__slices__slice")
            .style("fill", d => this._valueColorsScale(d.data.groupIndex))
            .style("opacity", d => d.data.opacity)
            .merge(slices)
            .transition()
            .duration(useTransition ? LG_D3_PIE_DEFAULT_DURATION : 0)
            .attrTween("d", this._interpolator.pieTween)
            .style("fill", d => this._valueColorsScale(d.data.groupIndex))
            .style("opacity", d => d.data.opacity);

        slices.exit().transition().attrTween("d", this._interpolator.pieExitTween).remove();
    }

    protected _create(): void {
        this._chart = this._svgG.append("g");
        this._xScale = d3.scaleBand();
        this._pie = d3.pie();
        this._pie.value(d => d.value);
        this._pie.sort(null);
        this._interpolator.layout(this._pie);

        this._data.forEach(() => {
            this._chart.append("g").attr("class", "lg-compare-pie-chart__group");
        });

        const groupsG = this._chart.selectAll(".lg-compare-pie-chart__group");
        groupsG.append("g").attr("class", "lg-compare-pie-chart__group__slices");
    }

    protected _convertData(): void {
        this._data = [];
        this._data[0] = [
            {
                groupIndex: 0,
                opacity: 1,
                value: this.percentage
            },
            {
                groupIndex: 1,
                opacity: 0,
                value: 1 - this.percentage
            }
        ];

        this._valueColorsScale = d3
            .scaleOrdinal<string, string>()
            .range(this._colorPalette.getColorsForType(this.colorConfiguration));
    }
}

type PieDimensionCalcInput = {
    width: number;
    height: number;
    margin: Margin;
};

type PieDimensions = {
    radius: number;
    verticalPadding: number;
    horizontalPadding: number;
    width: number;
};

function getPieDimensions({ width, height }: PieDimensionCalcInput): PieDimensions {
    const radius = Math.min(width / 2, height / 2);

    return {
        radius,
        verticalPadding: (width - 2 * radius) / 2,
        horizontalPadding: (height - 2 * radius) / 2,
        width
    };
}
